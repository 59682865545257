import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { useAuthMailOrderInitMutation } from 'services/mailOrder';
import useAuth from 'Hooks/useAuth';
import { useGetUserDetailsQuery } from 'services/user';
import { MailOrderProps } from '..';

const confirmNeedType = 'MounjaroProv';

interface MounjaroLayoutValues {
  initials: string;
}

const useStyles = makeStyles((theme) => ({
  screenBox: {
    padding: theme.spacing(1),
  },
}));

const MounjaroLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  onSaveData,
  contextData,
  savedData,
}) => {
  const dispatch = useDispatch();

  const { mailOrderAuthKeystring: mailOrderId } = contextData;

  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();
  const { data: userDetails } = useGetUserDetailsQuery(personId);
  const { firstName, lastName } = userDetails || {};
  const userInitials = `${firstName?.[0].toUpperCase()}${lastName?.[0].toUpperCase()}`;

  const [putAuthMailOrderInit] = useAuthMailOrderInitMutation();

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  const MounjaroSchema = Yup.object().shape({
    initials: Yup.string()
      .uppercase()
      .oneOf([userInitials], "Characters don't match your initials")
      .required('Initials Required'),
  });

  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        initials: (savedData.Mounjaro as string) || '',
      }}
      validationSchema={MounjaroSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSaveData({
          Mounjaro: values.initials,
        });

        const mailOrderInitInfo = {
          mailOrderId,
          authorizationInitials: values.initials,
          confirmationNeedType: confirmNeedType,
        };
        await putAuthMailOrderInit(mailOrderInitInfo);

        setSubmitting(false);
        nextOnClickCallback();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<MounjaroLayoutValues>) => (
        <Form>
          <Box
            className={classes.screenBox}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              Mounjaro Disclosure
            </Typography>
            <Typography align={'center'} variant={'body2'} color={'textPrimary'}>
              <i>Important information regarding your Mounjaro medication</i>
              <br />
              <br />
              Mounjaro is available in various dosages and comes in both one-time use pens and
              multi-use pens. If there are any changes to your prescribed dosage, please notify us
              immediately. The medication you will receive through your mail order is the multi-use
              KwikPen including 6 needles (4 for dosing, 2 extra). Each prefilled pen contains 4
              doses of your prescribed strength. For example, a 10mg pen will provide 4 doses of
              10mg. The KwikPen is designed for weekly use, providing a one-month (4-week) supply.
              Along with your medication, you will receive a patient leaflet with detailed
              instructions on how to use the KwikPen. Please note that the multi-use KwikPen
              requires pen needles, which must be attached before each injection. The pen also
              features a dose indicator window to help you track your medication. Be sure to
              carefully follow the instructions to prime and prepare your pen before each injection.
              After use, the pen can be stored in the refrigerator. Mounjaro can also be kept at
              room temperature for up to 21 days.
              <br />
              <br />
              If you have any questions or concerns, please don’t hesitate to reach out to us
            </Typography>
            <Box display={'flex'} marginTop={2}>
              <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
                I, {firstName} {lastName}, verify that I have read and understand the above
                disclosure regarding my order of Mounjaro.
              </Typography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              marginTop={1}
              marginBottom={1}
            >
              <Box flexBasis={'60%'}>
                <TextFieldComponent
                  label="Initial Here"
                  name={'initials'}
                  fullWidth={false}
                  inputProps={{ maxLength: 2 }}
                  onInput={(e: { target: { value: string } }) => {
                    e.target.value = e.target.value.slice(0, 2);
                  }}
                />
              </Box>
            </Box>
            <Box marginTop={1} marginBottom={1}>
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextOnClickCallback={submitForm}
                previousOnClickCallback={previousOnClickCallback}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default MounjaroLayout;
